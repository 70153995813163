import React, { useState } from 'react'
import FormMenImg from '../../assets/images/become an agent form/form_men_img_new.png';
import BgImg from '../../assets/images/home page images/backgroundArrow.png';
import ReactCodeInput from 'react-verification-code-input';

const Otp: React.FC = () => {

    const [otpCode, setOtpCode] = useState<string>("");
    const [isResentCode, setIsResentCode] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    return (
        <div className='nabar-section'>
            <div className="otp_form grid">
                <div className="otp_form_left_side_form">
                    <p className='otp_breadcrumb'>
                        <span className='otp_breadcrumb_first'>Become an Agent</span>
                        <span className='otp_breadcrumb_second'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
                                <path d="M6 3L11 8L6 13" stroke="#FF4600" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Personal details
                        </span>
                    </p>
                    <div className="otp_form_container">
                        <div className="otp_form_header">
                            <div className="otp_form_title">Personal details</div>
                            <div className="otp_form_step">Step 2/3</div>
                        </div>
                        <div className="otp_form_body">
                            <div className="otp_form_group">
                                <p className="otp_form_description">We’ve sent a one-time PIN to the cellphone number +27 074 1234</p>
                            </div>
                            <div className="otp_form_group">
                                <ReactCodeInput type='number' fields={4} className={`otp_input_container ${isSubmitted ? 'error' : ''}`} onChange={(e) => setOtpCode(e)} />
                            </div>
                            {isSubmitted &&
                                <div className="otp_form_group">
                                    <div className='otp_invalid'>
                                        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.500001 11C0.500001 4.92487 5.42487 -1.49276e-06 11.5 -9.61651e-07C17.5751 -4.30546e-07 22.5 4.92487 22.5 11C22.5 17.0751 17.5751 22 11.5 22C5.42487 22 0.5 17.0751 0.500001 11ZM11.5 4.74343C12.3284 4.74343 13 5.415 13 6.24343L13 11.7434C13 12.5719 12.3284 13.2434 11.5 13.2434C10.6716 13.2434 10 12.5719 10 11.7434L10 6.24343C10 5.415 10.6716 4.74343 11.5 4.74343ZM11.4905 14.9C10.6621 14.9 9.99049 15.5716 9.99049 16.4C9.99049 17.2285 10.6621 17.9 11.4905 17.9L11.5103 17.9C12.3387 17.9 13.0103 17.2285 13.0103 16.4C13.0103 15.5716 12.3387 14.9 11.5103 14.9L11.4905 14.9Z" fill="#FF1200" />
                                        </svg>
                                        <span>Invalid OTP entered</span>
                                    </div>
                                </div>
                            }
                            <div className="otp_form_group">
                                <span className='otp_resend_btn' onClick={() => setIsResentCode(true)}>Resend OTP {isResentCode && <span style={{ color: "#FF4600" }}>Sent!</span>}</span>
                            </div>
                            <div className="otp_form_group">
                                <button
                                    type='submit'
                                    className="otp_form_submit_btn"
                                    disabled={otpCode.length !== 4}
                                    onClick={() => setIsSubmitted(true)}
                                >
                                    <span>Next</span>
                                    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.88867 12H19.1109M19.1109 12L13.4969 6M19.1109 12L13.4969 18" stroke="#FFBF00" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </div>
                            <div className="otp_extra_info">
                                <a href="#">Change cellphone number</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="otp_form_right_side_img">
                    <img src={FormMenImg} alt="loading" />
                </div>
                <div className="otp_form_arrow_img">
                    <img src={BgImg} alt="loading" />
                </div>
            </div>
        </div>
    )
}

export { Otp };
