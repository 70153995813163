import React from 'react'
import { BeYourOwnBoss } from '../../components/become-an-agent/be-your-own-boss'
import { BecomeAnAgentHeader } from '../../components/become-an-agent/become-an-agent-header'
import { HereHelp } from '../../components/become-an-agent/here-help'
import { HowToBecomeAgent } from '../../components/become-an-agent/how-to-become-agent'
import { ItIsConvenient } from '../../components/become-an-agent/it-is-convenient'
import { MakeMoney } from '../../components/become-an-agent/make-money'
import { SalesAgent } from '../../components/become-an-agent/sales-agent'

export const whatIsGo121AgentModel = () => {
    return (
        <div>
            <BecomeAnAgentHeader />
            <BeYourOwnBoss />
            <MakeMoney />
            <HowToBecomeAgent />
            <ItIsConvenient />
            <HereHelp faqGUID={process.env.REACT_APP_HOME_PAGE_FAQ_GUID} />
            <SalesAgent />
        </div>
    )
}
