import React from 'react'
import FormMenImg from '../../assets/images/become an agent form/form_men_img_new.png';
import BgImg from '../../assets/images/home page images/backgroundArrow.png';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const FormSchema = Yup.object({
    firstname: Yup.string().required("First name is required"),
    surname: Yup.string().required("Surname is required"),
    cellphone: Yup.string()
        .max(10, "Please enter a valid 10-digit number.")
        .min(10, "Please enter a valid 10-digit number.")
        .matches(new RegExp('[0-9]{10}'), "Please enter a valid 10-digit number.")
        .required("Please enter a valid 10-digit number.")
});

const PersonalDetailsForm: React.FC = () => {

    const formik = useFormik({
        validationSchema: FormSchema,
        initialValues: {
            firstname: "",
            surname: "",
            cellphone: ""
        },
        onSubmit: (value) => {
            console.log(value);
        },
    })

    return (
        <div className='nabar-section'>
            <div className="personal_details_form grid">
                <div className="personal_details_form_left_side_form">
                    <p className='personal_details_breadcrumb'>
                        <span className='personal_details_breadcrumb_first'>Become an Agent</span>
                        <span className='personal_details_breadcrumb_second'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
                                <path d="M6 3L11 8L6 13" stroke="#FF4600" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Personal details
                        </span>
                    </p>
                    <div className="personal_details_form_container">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="personal_details_form_header">
                                <div className="personal_details_form_title">Personal details</div>
                                <div className="personal_details_form_step">Step 1/3</div>
                            </div>
                            <div className="personal_details_form_body">
                                <div className="personal_details_form_group">
                                    <label htmlFor="" className={`personal_details_form_label ${formik.touched.firstname && formik.errors.firstname ? 'error' : ''}`}>First name</label>
                                    <input type="text" className={`personal_details_form_input ${formik.touched.firstname && formik.errors.firstname ? 'error' : ''}`} {...formik.getFieldProps("firstname")} />
                                    {formik.touched.firstname && formik.errors.firstname &&
                                        <div className="personal_details_form_error">
                                            {formik.errors.firstname}
                                        </div>
                                    }
                                </div>
                                <div className="personal_details_form_group">
                                    <label htmlFor="" className={`personal_details_form_label ${formik.touched.surname && formik.errors.surname ? 'error' : ''}`}>Surname</label>
                                    <input type="text" className={`personal_details_form_input ${formik.touched.surname && formik.errors.surname ? 'error' : ''}`} {...formik.getFieldProps("surname")} />
                                    {formik.touched.surname && formik.errors.surname &&
                                        <div className="personal_details_form_error">
                                            {formik.errors.surname}
                                        </div>
                                    }
                                </div>
                                <div className="personal_details_form_group">
                                    <label htmlFor="" className={`personal_details_form_label ${formik.touched.cellphone && formik.errors.cellphone ? 'error' : ''}`}>Cellphone number</label>
                                    <input
                                        type="string"
                                        className={`personal_details_form_input ${formik.touched.cellphone && formik.errors.cellphone ? 'error' : ''}`}
                                        {...formik.getFieldProps("cellphone")}
                                    />
                                    {formik.touched.cellphone && formik.errors.cellphone &&
                                        <div className="personal_details_form_error">
                                            {formik.errors.cellphone}
                                        </div>
                                    }
                                </div>
                                <div className="personal_details_form_group">
                                    <button
                                        type='submit'
                                        className="personal_details_form_submit_btn"
                                        disabled={
                                            formik.values.cellphone === "" ||
                                                formik.values.firstname === "" ||
                                                formik.values.surname === "" ||
                                                formik.errors.cellphone ||
                                                formik.errors.firstname ||
                                                formik.errors.surname ? true : false
                                        }
                                    >
                                        <span>Next</span>
                                        <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.88867 12H19.1109M19.1109 12L13.4969 6M19.1109 12L13.4969 18" stroke="#FFBF00" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="personal_details_extra_info">
                                    <span>Already have an account?</span>
                                    <a href="#">Sign in</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="personal_details_form_right_side_img">
                    <img src={FormMenImg} alt="loading" />
                </div>
                <div className="personal_details_form_arrow_img">
                    <img src={BgImg} alt="loading" />
                </div>
            </div>
        </div>
    )
}

export { PersonalDetailsForm };
