import React from 'react'
import MakeMoneyRightSidePersonPic from '../../assets/images/become an agent/make money right side person pic.svg'
import MakeMoneyRightSidePersonPicMobile from '../../assets/images/become an agent/make_moneyM.png'
export const MakeMoney = () => {
    return (
        <div className="header-section-white">
            <div className="make_money grid">
                <div className="make_money_left_side_content">
                    <p className="make_money_first_line font-xsmall">what are the benefits</p>
                    <h1 className="make_money_second_line font-large">The chance to save and make money</h1>
                    <div>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none" preserveAspectRatio="xMinYMin">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="make_money_third_line font-small">Cut down on transport costs by working in your community, close to home.</li>
                        </ul>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none" preserveAspectRatio="xMinYMin">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="make_money_third_line font-small">Using technology like what is used by Uber and Lyft, allows agents to find areas to work in, without competing with many other agents in the same place.</li>
                        </ul>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none" preserveAspectRatio="xMinYMin">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="make_money_third_line font-small">Become your own boss.</li>
                        </ul>
                    </div>
                </div>
                <div className="make_money_right_side_img">
                    <img src={MakeMoneyRightSidePersonPic} alt="loading" />
                </div>
                <div className="make_money_right_side_img_mobile">
                    <img src={MakeMoneyRightSidePersonPicMobile} alt="loading" />
                </div>
            </div>
        </div>
    )
}
