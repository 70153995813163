import React, { useState } from 'react'
import { HomePage } from '../pages/home/home-page'
import { HashRouter, Switch, Route, useHistory, useLocation, Redirect } from 'react-router-dom'
import { whatIsGo121AgentModel } from '../pages/become-an-agent/become-an-agent'
import { NavBar } from '../components/nav-bar'
import { Footer } from '../components/footer'
import { FooterCopyright } from '../components/footer-copyright'
import { ApplicationFormComplete } from '../pages/application-form-complete/application-form-complete'
import { PersonalDetailsForm } from '../pages/become-an-agent-form-2/personal-details-form'
import { Otp } from '../pages/become-an-agent-form-5/otp'
import { AddressDetails } from '../pages/address-details-form 4/address-details'
import { WhatIsGo } from '../pages/what-is-go121/what-is-go'
import { BecomeaPartner } from '../pages/become-a-partner/become-a-partner'
import { SignupForm } from '../pages/partner-signup-form/signup-form'
import { SignupFormError } from '../pages/partner-signup-form/signup-form-error'
import { SignupFormThankYou } from '../pages/partner-signup-form/signup-form-thank-you'
import { About } from '../pages/about/about'
import { HelpCenter } from '../pages/help-center/help-center'
import { HelpCenterError } from '../pages/help-center-error/help-center-error'
import { HelpCenterThankYou } from '../pages/help-center-thank-you/help-center-thank-you'
import { HelpCenterTopic } from '../pages/help-center-topic/help-center-topic'
import { helpCenterSearchOnResult } from '../pages/help-center-search-on-result/help-center-search-on-result'
import { HelpCenterArticale } from '../pages/help-center-articale/help-center-articale'
import { VerifyAnAgent } from '../pages/verify-an-agent/verify-an-agent'
import { VerifyAnAgentError } from '../pages/verify-an-agent-error/verify-an-agent-error'
import { VeriffyAnAgentSuccess } from '../pages/verify-an-agent-success/veriffy-an-agent-success'
import { TermsAndConditions } from '../pages/terms and conditions/terms-and-conditions'
import { PrivacyPolicy } from '../pages/privacy-policy/privacy-policy'
import { ErrorPage } from '../pages/error-page/error-page'
import { ThankYouPage } from '../pages/thank-you/thank-you-page'
import { ErrorMessage } from '../pages/error-message/error-message'
import { SideNavBar } from '../components/side-nav-bar'
import { CellPhoneInput } from '../pages/become-an-agent-form-alt/cell-phone-input'

export const Routes = () => {
    const history = useHistory();
    const path = history.location.pathname;
    const location = useLocation()
    return (
        <div>
            <HashRouter>
                {location.hash !== '#/nav-link' && <NavBar />}
                <Switch>
                    <Route exact path="/" component={HomePage}></Route>
                    <Route exact path="/home" component={HomePage}></Route>
                    <Route exact path="/become-go-121-agent" component={whatIsGo121AgentModel}></Route>
                    <Route exact path="/personal-details-form" component={PersonalDetailsForm}></Route>
                    <Route exact path="/application-form-complete" component={ApplicationFormComplete}></Route>
                    <Route exact path="/otp" component={Otp}></Route>
                    <Route exact path="/cell-phone-input" component={CellPhoneInput}></Route>
                    <Route exact path="/address-details" component={AddressDetails}></Route>
                    <Route exact path="/what-is-go121-agent-model" component={WhatIsGo}></Route>
                    <Route exact path="/corporate-partner" component={BecomeaPartner}></Route>
                    <Route exact path="/signup-form" component={SignupForm}></Route>
                    <Route exact path="/signup-form-error" component={SignupFormError}></Route>
                    <Route exact path="/signup-form-thank-you" component={SignupFormThankYou}></Route>
                    <Route exact path="/about-direct-sales" component={About}></Route>
                    <Route exact path="/help-center" component={HelpCenter}></Route>
                    <Route exact path="/help-center-error" component={HelpCenterError}></Route>
                    <Route exact path="/help-center-thank-you" component={HelpCenterThankYou}></Route>
                    <Route exact path="/help-center-topic" component={HelpCenterTopic}></Route>
                    <Route exact path="/help-center-search-on-result" component={helpCenterSearchOnResult}></Route>
                    <Route exact path="/help-center-articale" component={HelpCenterArticale}></Route>
                    <Route exact path="/verify-an-angent" component={VerifyAnAgent}></Route>
                    <Route exact path="/verify-an-angent-error" component={VerifyAnAgentError}></Route>
                    <Route exact path="/verify-an-angent-success" component={VeriffyAnAgentSuccess}></Route>
                    <Route exact path="/terms-conditions" component={TermsAndConditions}></Route>
                    <Route exact path="/privacy-policy" component={PrivacyPolicy}></Route>
                    <Route exact path="/thank-you" component={ThankYouPage}></Route>
                    <Route exact path="/error-message" component={ErrorMessage}></Route>
                    <Route exact path="/nav-link" component={SideNavBar}></Route>
                    <Route exact path="/error-404" component={ErrorPage}></Route>
                    <Redirect to="/error-404" />
                </Switch>
                {location.hash !== '#/nav-link' && location.hash !== '#/error-404' && <Footer />}
                {location.hash !== '#/nav-link' && location.hash !== '#/error-404' && <FooterCopyright />}
            </HashRouter>
        </div>
    )
}
