import React from 'react'
import FormMenImg from '../../assets/images/become an agent form/form_men_img_new.png';
import BgImg from '../../assets/images/home page images/backgroundArrow.png';

const CellPhoneInput: React.FC = () => {

    return (
        <div className='nabar-section'>
            <div className="cellphone_input_form grid">
                <div className="cellphone_input_form_left_side_form">
                    <p className='cellphone_input_breadcrumb'>
                        <span className='cellphone_input_breadcrumb_first'>Become an Agent</span>
                        <span className='cellphone_input_breadcrumb_second'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
                                <path d="M6 3L11 8L6 13" stroke="#FF4600" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Personal details
                        </span>
                    </p>
                    <div className="cellphone_input_form_container">
                        <div className="cellphone_input_form_header">
                            <div className="cellphone_input_form_title">Personal details</div>
                            <div className="cellphone_input_form_step">Step 2/3</div>
                        </div>
                        <div className="cellphone_input_form_body">
                            <div className="cellphone_input_form_group">
                                <p className="cellphone_input_form_description">We’ve sent a one-time PIN to the cellphone number +27 074 1234</p>
                            </div>
                            <div className="cellphone_input_form_group">
                                <label htmlFor="" className={`cellphone_input_form_label`}>First name</label>
                                <input type="text" className={`cellphone_input_form_input`} />
                            </div>
                            <div className="cellphone_input_form_group">
                                <button
                                    type='submit'
                                    className="cellphone_input_form_submit_btn"
                                >
                                    <span>Next</span>
                                    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.88867 12H19.1109M19.1109 12L13.4969 6M19.1109 12L13.4969 18" stroke="#FFBF00" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </div>
                            <div className="cellphone_input_extra_info">
                                Already have an account?{" "}
                                <a href="#">Sign in</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cellphone_input_form_right_side_img">
                    <img src={FormMenImg} alt="loading" />
                </div>
                <div className="cellphone_input_form_arrow_img">
                    <img src={BgImg} alt="loading" />
                </div>
            </div>
        </div>
    )
}

export { CellPhoneInput };
