import React, { useEffect, useState } from 'react'
import axios from '../../utils/api';

export const PrivacyPolicyContent: React.FC = () => {

    const [privacyPolicyString, setPrivacyPolicyString] = useState("");

    useEffect(() => {
        axios.get(`api/v1/Catalog/GetPolicy?PolicyId=${process.env.REACT_APP_PRIVACY_POLICY_GUID}`).then(({ data }) => {
            setPrivacyPolicyString(data.policy)
        })
    }, [])
    return (
        <div className='header-section-white'>
            <div className='privacy_and_policy_content grid'>
                <div className='privacy_and_policy_info' dangerouslySetInnerHTML={{ __html: privacyPolicyString }}></div>
            </div>
        </div>
    )
}
