import React, { useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom';
import navbarLoginImg from '../assets/images/home page images/Frame 13610.png';
import loginImg from '../assets/images/home page images/Login.svg';
import { SideNavBar } from './side-nav-bar';
// import logoImg from '../assets/images/home page images/Desktop.svg';
// import menuBar from '../assets/images/home page images/Menu_bar.svg';



export const NavBar = () => {
    const [showNavbar, setShowNavbar] = useState(false);
    const history = useHistory();
    const handleShowNavbar = () => {
        setShowNavbar(true);
    }
    const handleHideNavbar = () => {
        setShowNavbar(false);
    }
    const hadldeNavBar = () => {
        const path: any = history.location.pathname;
        sessionStorage.setItem('previousLink', path)
        history.push('/nav-link');
    }
    return (
        <div className="nabar-section">
            <div className="nav-bar grid">
                <div className='header_div'>
                    <div className="nav-bar-logo">
                        <Link to="/" className='logo_link'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="123" height="32" viewBox="0 0 123 32" fill="none">
                                <g clip-path="url(#clip0_7641_24278)">
                                    <path d="M33.0718 17.0646C33.065 18.6575 33.4085 20.2323 34.078 21.6778C34.7366 23.094 35.6813 24.3587 36.8526 25.3926C38.0694 26.4685 39.4771 27.3071 41.0029 27.8652C42.6527 28.4731 44.3986 28.779 46.157 28.7684C47.9161 28.7789 49.6629 28.473 51.3135 27.8652C52.8419 27.3078 54.2498 26.4638 55.4614 25.3787C56.6206 24.3338 57.5633 23.0716 58.2359 21.6639C58.9081 20.2192 59.2518 18.6438 59.2422 17.0507C59.2492 15.4579 58.9057 13.883 58.2359 12.4375C57.5779 11.021 56.6332 9.7562 55.4614 8.72282C54.2453 7.64718 52.8385 6.80854 51.3135 6.25017C49.6629 5.64163 47.9163 5.33493 46.157 5.34472C44.3984 5.3348 42.6525 5.64151 41.0029 6.25017C39.4787 6.81171 38.0716 7.65 36.8526 8.72282C35.6877 9.76252 34.7441 11.0259 34.078 12.4375C33.4038 13.8864 33.0602 15.4669 33.0718 17.0646ZM42.5249 17.2062C42.5232 16.7392 42.623 16.2774 42.8177 15.8527C42.9993 15.4294 43.2667 15.0484 43.6031 14.7337C43.941 14.4121 44.3347 14.1549 44.765 13.9745C45.2052 13.7845 45.6799 13.6873 46.1593 13.6889C46.6388 13.6873 47.1134 13.7845 47.5536 13.9745C47.9884 14.1462 48.3837 14.4045 48.7154 14.7337C49.0455 15.0571 49.3126 15.4389 49.5033 15.8597C49.6988 16.2841 49.7994 16.746 49.7984 17.2132C49.7994 17.6813 49.6987 18.1438 49.5033 18.5691C49.3211 18.9913 49.0537 19.3715 48.7178 19.6859C48.3794 20.0074 47.9859 20.2653 47.5559 20.4474C47.1158 20.6374 46.6411 20.7346 46.1617 20.7329C45.6822 20.7346 45.2075 20.6374 44.7674 20.4474C44.3324 20.2749 43.9371 20.0158 43.6055 19.6859C43.2776 19.3647 43.0113 18.9862 42.82 18.5691C42.6211 18.1428 42.5225 17.6766 42.5319 17.2062H42.5249Z" fill="#FF4600" />
                                    <path d="M18.8065 32.0002C21.458 32.0002 23.8096 31.7077 25.7779 31.1226C27.7461 30.5375 29.3705 29.8851 30.5462 29.17V13.8212H17.4518V20.7864H20.64V22.3814C20.4675 22.448 20.2871 22.4917 20.1032 22.5114C19.8355 22.5414 19.5661 22.5523 19.2968 22.5439C18.5023 22.5525 17.7135 22.4091 16.973 22.1214C16.2407 21.8679 15.5673 21.4691 14.9932 20.9489C14.4096 20.4469 13.9399 19.8264 13.6152 19.1287C13.2689 18.3625 13.0967 17.5291 13.1109 16.6886C13.1033 15.8875 13.2517 15.0927 13.5478 14.3483C13.8437 13.6239 14.3034 12.9778 14.891 12.4607C15.5504 11.9014 16.3164 11.4812 17.1427 11.2256C18.1405 10.9062 19.1833 10.7494 20.231 10.7613C21.0282 10.7713 21.8241 10.8256 22.6152 10.9238C23.4633 11.0394 24.3042 11.2021 25.1342 11.4113C25.9731 11.6064 26.7794 11.8339 27.5858 12.0939C28.3921 12.354 29.0962 12.614 29.7678 12.874V2.24062C28.5246 1.59055 26.9467 1.07976 25.0993 0.647925C23.1374 0.210371 21.1328 -0.00765994 19.1226 -0.0021488C16.4974 -0.0267263 13.8869 0.390837 11.4006 1.233C9.19169 1.97097 7.14194 3.11861 5.35881 4.61572C3.67928 6.01935 2.32839 7.77397 1.40141 9.75596C0.451946 11.7907 -0.0299835 14.0118 -0.00911575 16.2567C-0.0286365 18.3982 0.41856 20.5182 1.30147 22.4696C2.17648 24.3915 3.45944 26.1003 5.06136 27.4775C6.81389 28.9505 8.83127 30.0766 11.0056 30.7952C13.5225 31.62 16.1578 32.0271 18.8065 32.0002Z" fill="#FF4600" />
                                    <path d="M110.879 7.44572V14.5362L114.304 13.8211V26.342H123V4.9104L110.879 7.44572Z" fill="white" />
                                    <path d="M64.9799 13.8211V26.342H73.643V4.9104L61.5593 7.44572V14.5362L64.9799 13.8211Z" fill="white" />
                                    <path d="M87.1485 19.3816L78.2788 31.8793L78.3115 31.9768H103.831V21.9935H96.6111L96.5785 21.896C97.5078 21.0811 98.4584 20.236 99.3999 19.3909C100.305 18.551 101.126 17.6248 101.852 16.6258C102.553 15.669 103.139 14.6325 103.596 13.5379C104.044 12.4314 104.272 11.2487 104.268 10.0553C104.264 8.83993 104.028 7.6365 103.571 6.51009C103.076 5.28949 102.308 4.19884 101.324 3.3224C100.283 2.37979 98.9747 1.6322 97.3294 1.01463C95.6841 0.397054 93.7016 0.137031 91.3176 0.137031C89.2471 0.110202 87.1819 0.35054 85.1736 0.852103C83.4699 1.28082 81.8063 1.85725 80.2028 2.57482V12.2981C80.6674 12.103 81.2118 11.9081 81.7805 11.6805C82.35 11.453 82.9566 11.2905 83.561 11.1279C84.1722 10.9521 84.7925 10.8111 85.4196 10.7054C86.0081 10.6044 86.6033 10.5501 87.2001 10.5428C88.0505 10.517 88.894 10.7071 89.6518 11.0955C90.3229 11.4855 90.6251 12.103 90.6251 12.9528C90.6206 13.3307 90.5522 13.7052 90.4232 14.0602C90.2607 14.5271 90.0466 14.9742 89.7839 15.3929C89.4505 15.989 89.0808 16.5643 88.6776 17.1156C88.2221 17.9058 87.7111 18.663 87.1485 19.3816Z" fill="white" />
                                    <path d="M79.7867 20.8722C81.0007 20.8722 81.9855 19.9264 81.9855 18.7595C81.9855 17.5927 81.0007 16.6467 79.7867 16.6467C78.5726 16.6467 77.5886 17.5927 77.5886 18.7595C77.5886 19.9264 78.5726 20.8722 79.7867 20.8722Z" fill="#FF4600" />
                                    <path d="M107.579 20.8722C108.793 20.8722 109.777 19.9264 109.777 18.7595C109.777 17.5927 108.793 16.6467 107.579 16.6467C106.365 16.6467 105.381 17.5927 105.381 18.7595C105.381 19.9264 106.365 20.8722 107.579 20.8722Z" fill="#FF4600" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_7641_24278">
                                        <rect width="123" height="32" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124 32" fill="none">
                                <path d="M32.7266 16C32.7266 18.2439 33.197 20.3577 34.1042 22.3089C35.0114 24.2601 36.2882 25.9512 37.901 27.3821C39.5138 28.813 41.3954 29.9512 43.5794 30.7642C45.7634 31.5772 48.1154 32 50.6354 32C53.1554 32 55.5074 31.5772 57.6914 30.7642C59.8754 29.9512 61.7906 28.813 63.3698 27.3821C64.9826 25.9512 66.2258 24.2276 67.1666 22.3089C68.0738 20.3577 68.5442 18.2764 68.5442 16C68.5442 13.7561 68.0738 11.6423 67.1666 9.69105C66.2594 7.73983 64.9826 6.04878 63.3698 4.61788C61.757 3.18699 59.8754 2.04878 57.6914 1.23577C55.5074 0.422764 53.1554 0 50.6354 0C48.1154 0 45.7634 0.422764 43.5794 1.23577C41.3954 2.0813 39.4802 3.18699 37.901 4.61788C36.2882 6.04878 35.045 7.73983 34.1042 9.69105C33.197 11.6423 32.7266 13.7236 32.7266 16ZM45.6626 16.1951C45.6626 15.5447 45.797 14.9268 46.0658 14.3415C46.3346 13.7561 46.6706 13.2358 47.141 12.813C47.5778 12.3902 48.1154 12.0325 48.7202 11.7724C49.325 11.5122 49.9634 11.3821 50.6354 11.3821C51.3074 11.3821 51.9458 11.5122 52.5506 11.7724C53.1554 12.0325 53.693 12.3577 54.1298 12.813C54.5666 13.2358 54.9362 13.7561 55.205 14.3415C55.4738 14.9268 55.6082 15.5447 55.6082 16.1951C55.6082 16.8455 55.4738 17.4634 55.205 18.0488C54.9362 18.6341 54.6002 19.1545 54.1298 19.5772C53.693 20 53.1554 20.3577 52.5506 20.6179C51.9458 20.878 51.3074 21.0081 50.6354 21.0081C49.9634 21.0081 49.325 20.878 48.7202 20.6179C48.1154 20.3577 47.5778 20.0325 47.141 19.5772C46.7042 19.1545 46.3346 18.6341 46.0658 18.0488C45.797 17.4959 45.6626 16.878 45.6626 16.1951Z" fill="#FF4600" />
                                <path d="M18.816 32C21.4704 32 23.8224 31.7073 25.8048 31.1219C27.8208 30.5366 29.4 29.8862 30.576 29.1707V13.8211H17.472V20.7805H20.664V22.374C20.5296 22.439 20.328 22.4715 20.1264 22.5041C19.9248 22.5366 19.656 22.5366 19.32 22.5366C18.5136 22.5366 17.7408 22.4065 17.0016 22.1138C16.2624 21.8536 15.5904 21.4634 15.0192 20.9431C14.448 20.4553 13.9776 19.8374 13.6416 19.1219C13.3056 18.4065 13.1376 17.5935 13.1376 16.6829C13.1376 15.8699 13.272 15.0894 13.5744 14.3415C13.8768 13.626 14.3136 12.9756 14.9184 12.4553C15.5232 11.935 16.2624 11.5122 17.1696 11.2195C18.0432 10.9268 19.0848 10.7642 20.2608 10.7642C21 10.7642 21.7728 10.8293 22.6464 10.9268C23.4864 11.0569 24.3264 11.187 25.1664 11.4146C26.0064 11.6097 26.8128 11.8374 27.6192 12.0976C28.4256 12.3577 29.1312 12.6179 29.8032 12.878V2.2439C28.56 1.5935 26.9808 1.07317 25.1328 0.650406C23.2512 0.227642 21.2688 0 19.152 0C16.3296 0 13.776 0.422764 11.424 1.23577C9.072 2.04878 7.056 3.18699 5.376 4.61788C3.6624 6.04878 2.352 7.77235 1.4112 9.75609C0.4704 11.7398 0 13.9187 0 16.2602C0 18.5041 0.4368 20.5528 1.3104 22.4715C2.184 24.3902 3.4272 26.0488 5.0736 27.4797C6.72 28.878 8.7024 30.0162 11.0208 30.7967C13.3392 31.5772 15.9264 32 18.816 32Z" fill="#FF4600" />
                                <path d="M110.947 7.44747V14.5369L114.374 13.8215V26.3418H123.077V4.91089L110.947 7.44747Z" fill="white" />
                                <path d="M73.8193 13.8215V26.3418H82.4881V4.91089L70.3921 7.44747V14.5369L73.8193 13.8215Z" fill="white" />
                                <path d="M91.8289 19.3819L82.9585 31.8697L82.9921 31.9673H108.528V21.9835H101.304L101.271 21.886C102.211 21.073 103.152 20.2274 104.093 19.3819C105.034 18.5039 105.84 17.5933 106.546 16.6177C107.251 15.6421 107.856 14.6014 108.293 13.5282C108.73 12.4551 108.965 11.2843 108.965 10.0486C108.965 8.87785 108.73 7.67459 108.259 6.50386C107.789 5.33313 107.05 4.25996 106.008 3.31687C104.967 2.37378 103.656 1.62582 102.01 1.00793C100.363 0.422566 98.3809 0.129883 95.9953 0.129883C93.7441 0.129883 91.6945 0.357525 89.8465 0.84533C87.9985 1.33313 86.3521 1.88598 84.8737 2.56891V12.2925C85.3441 12.0974 85.8817 11.9022 86.4529 11.6746C87.0241 11.4795 87.6289 11.2843 88.2337 11.1217C88.8385 10.9591 89.4433 10.7965 90.0817 10.699C90.6865 10.6014 91.2913 10.5364 91.8625 10.5364C92.8369 10.5364 93.6769 10.7315 94.3153 11.0892C94.9873 11.4795 95.2897 12.0974 95.2897 12.9429C95.2897 13.3006 95.2225 13.6583 95.0881 14.0486C94.9537 14.4388 94.7521 14.8941 94.4497 15.3819C94.1809 15.8697 93.8113 16.4551 93.3409 17.1055C93.0049 17.7559 92.4673 18.5039 91.8289 19.3819Z" fill="white" />
                            </svg> */}
                        </Link>
                    </div>
                    <div className="nav-bar-links">
                        <ul className="list-inline d-flex content_margin_bottom">
                            <li className='navbar-font-xsmall'><NavLink to="/become-go-121-agent" className="text-decoration-none text-white active_class">Become an Agent</NavLink></li>
                            <li className='navbar-font-xsmall nav_link_m_l'><NavLink to="/what-is-go121-agent-model" className="text-decoration-none text-white active_class">What is Go121?</NavLink></li>
                            <li className='navbar-font-xsmall nav_link_m_l'><NavLink to="/corporate-partner" className="text-decoration-none text-white active_class">Corporate Partner</NavLink></li>
                            <li className='navbar-font-xsmall nav_link_m_l'><NavLink to="/about-direct-sales" className="text-decoration-none text-white active_class">About</NavLink></li>
                            <li className='navbar-font-xsmall nav_link_m_l'><NavLink to="/help-center" className="text-decoration-none text-white active_class">Help Centre</NavLink></li>
                            { <li className='navbar-font-xsmall nav_link_m_l d-none'><NavLink to="/verify-an-angent" className="text-decoration-none text-white active_class">Verify Your Agent</NavLink></li> }
                        </ul>
                    </div>
                    <div className="nav-bar-right-side-login-img" style={{ opacity: 0 }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 24" fill="none">
                            <path d="M7.42 16V14.404H2.87V6.074H1.19V16H7.42ZM11.6443 14.754C10.6503 14.754 9.74028 13.998 9.74028 12.584C9.74028 11.17 10.6503 10.442 11.6443 10.442C12.6523 10.442 13.5483 11.17 13.5483 12.584C13.5483 14.012 12.6523 14.754 11.6443 14.754ZM11.6443 8.972C9.61428 8.972 8.11628 10.498 8.11628 12.584C8.11628 14.684 9.61428 16.21 11.6443 16.21C13.6883 16.21 15.1863 14.684 15.1863 12.584C15.1863 10.498 13.6883 8.972 11.6443 8.972ZM16.1257 16.378C16.3077 17.75 17.5677 18.87 19.3737 18.87C21.9357 18.87 22.8737 17.176 22.8737 15.356V9.182H21.3057V10.05C21.0117 9.49 20.3537 9.056 19.2897 9.056C17.4137 9.056 16.1397 10.554 16.1397 12.374C16.1397 14.292 17.4697 15.692 19.2897 15.692C20.2837 15.692 20.9697 15.23 21.2637 14.698V15.412C21.2637 16.798 20.6197 17.456 19.3317 17.456C18.3937 17.456 17.7357 16.826 17.6237 15.986L16.1257 16.378ZM19.5557 14.306C18.4917 14.306 17.7777 13.564 17.7777 12.374C17.7777 11.212 18.5197 10.456 19.5557 10.456C20.5637 10.456 21.3057 11.212 21.3057 12.374C21.3057 13.55 20.5917 14.306 19.5557 14.306ZM26.3298 16V9.182H24.7198V16H26.3298ZM24.4678 6.788C24.4678 7.376 24.9438 7.838 25.5178 7.838C26.1058 7.838 26.5818 7.376 26.5818 6.788C26.5818 6.2 26.1058 5.724 25.5178 5.724C24.9438 5.724 24.4678 6.2 24.4678 6.788ZM29.9089 12.066C29.9089 11.17 30.3989 10.456 31.3229 10.456C32.3449 10.456 32.7229 11.128 32.7229 11.968V16H34.3469V11.688C34.3469 10.19 33.5489 8.986 31.8829 8.986C31.1269 8.986 30.3149 9.308 29.8669 10.092V9.182H28.2849V16H29.9089V12.066Z" fill="white" />
                            <path d="M61 0C54.383 0 49 5.383 49 12C49 18.617 54.383 24 61 24C67.617 24 73 18.617 73 12C73 5.383 67.617 0 61 0ZM61 22C58.307 22 55.864 20.925 54.064 19.187C54.362 17.278 55.74 16.54 57.5 15.954C58.683 15.56 59.194 14.517 59.419 13.673C57.997 13.059 57 11.647 57 10V9C57 6.791 58.791 5 61 5C63.209 5 65 6.791 65 9V10C65 11.647 64.003 13.059 62.581 13.673C62.808 14.519 63.322 15.562 64.5 15.954C66.262 16.541 67.641 17.256 67.937 19.187C66.137 20.925 63.693 22 61 22Z" fill="#FFBF00" />
                        </svg>
                    </div>
                </div>
                <div className="menu-bar-icon">
                    <ul className="list-inline d-flex">
                        <li className="user_icon" style={{ opacity: 0 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                                <path d="M4.56998 19.1099L4.52994 19.3664L4.7167 19.5467C6.60518 21.3701 9.171 22.5 12 22.5C14.8289 22.5 17.3958 21.3702 19.2843 19.5467L19.4704 19.367L19.4312 19.1112C19.2645 18.0235 18.7824 17.2513 18.0852 16.6798C17.4083 16.1249 16.5487 15.7763 15.658 15.4796L15.6579 15.4796C14.8718 15.218 14.433 14.5887 14.1873 13.9308C15.5649 13.1622 16.5 11.6921 16.5 10V9C16.5 6.51486 14.4851 4.5 12 4.5C9.51486 4.5 7.5 6.51486 7.5 9V10C7.5 11.6924 8.43532 13.1627 9.81334 13.9311C9.56931 14.5873 9.13195 15.2165 8.34205 15.4796C7.44973 15.7767 6.59167 16.131 5.91558 16.6887C5.22059 17.262 4.73809 18.033 4.56998 19.1099ZM0.5 12C0.5 5.65914 5.65914 0.5 12 0.5C18.3409 0.5 23.5 5.65914 23.5 12C23.5 18.3409 18.3409 23.5 12 23.5C5.65914 23.5 0.5 18.3409 0.5 12Z" fill="#FFBF00" stroke="#FFBF00" />
                            </svg>
                        </li>
                        <li className="menu_icon" onClick={() => hadldeNavBar()}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                                <path d="M5 12H19M5 6H19M5 18H19" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
