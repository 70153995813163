import React from 'react'

export const PopularSalesAgent = () => {
    return (
        <div className='header-section-white'>
            <div className="help_center_here_help grid">
                <div className="agent_faq_left_side_content">
                    <p className="here_help_left_side_first_line font-xsmall">FAQS</p>
                    <h1 className="here_help_left_side_second_line">Popular Sales Agent FAQ’s</h1>
                </div>
                <div className="agent_faq_right_side_content">
                    <div className="ps-2">
                        <div className="row border-bottom here_help_right_side_content_padding border-top pt-3 pb-1">
                            {/* <div className="col-11">
                                <p className="here_help_right_first_line">What is an agent ID?</p>
                            </div>
                            <div className="col-1 text-end">
                                <div className="plus_img">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
                                        <path d="M9 2V16.1421M16.0711 9.07107H1.92893" stroke="#FF4600" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div> */}
                            <div className="here_help_right_first_line">
                                What is an agent ID?
                            </div>
                            <div className="text-end faq-add">
                                <div className="plus_img">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
                                        <path d="M9 2V16.1421M16.0711 9.07107H1.92893" stroke="#FF4600" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="row border-bottom here_help_right_side_content_padding pt-3 pb-1">
                            <div className="here_help_right_first_line">
                                Where can I find the agent ID?
                            </div>
                            <div className="faq-add text-end">
                                <div className="plus_img">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
                                        <path d="M9 2V16.1421M16.0711 9.07107H1.92893" stroke="#FF4600" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="row border-bottom here_help_right_side_content_padding pt-3 pb-1">
                            <div className="here_help_right_first_line">
                                What happens if I can’t find the agent?
                            </div>
                            <div className="faq-add text-end">
                                <div className="plus_img">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
                                        <path d="M9 2V16.1421M16.0711 9.07107H1.92893" stroke="#FF4600" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="row border-bottom here_help_right_side_content_padding pt-3 pb-1">
                            <div className="here_help_right_first_line">
                                Where can I report agents?
                            </div>
                            <div className="faq-add text-end">
                                <div className="plus_img">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
                                        <path d="M9 2V16.1421M16.0711 9.07107H1.92893" stroke="#FF4600" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="row border-bottom here_help_right_side_content_padding pt-3 pb-1">
                            <div className="here_help_right_first_line">
                                How can I become a Go121 agent?
                            </div>
                            <div className="faq-add text-end">
                                <div className="plus_img">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
                                        <path d="M9 2V16.1421M16.0711 9.07107H1.92893" stroke="#FF4600" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="here_help_right_side_content">
                    <div className="">
                        <div className="row border-bottom border-top pt-3 pb-1">
                            <div className="col-8">
                                <p className="here_help_right_first_line font-small">What is an agent ID?</p>
                            </div>
                            <div className="col-4 text-end">
                                <div className="plus_img">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
                                        <path d="M9 2V16.1421M16.0711 9.07107H1.92893" stroke="#FF4600" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="row border-bottom pt-3 pb-1">
                            <div className="col-8">
                                <p className="here_help_right_first_line font-small">Where can I find the agent ID?</p>
                            </div>
                            <div className="col-4 text-end">
                                <div className="plus_img">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
                                        <path d="M9 2V16.1421M16.0711 9.07107H1.92893" stroke="#FF4600" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="row border-bottom  pt-3 pb-1">
                            <div className="col-8">
                                <p className="here_help_right_first_line font-small">What happens if I can’t find the agent?</p>
                            </div>
                            <div className="col-4 text-end">
                                <div className="plus_img">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
                                        <path d="M9 2V16.1421M16.0711 9.07107H1.92893" stroke="#FF4600" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="row border-bottom pt-3 pb-1">
                            <div className="col-8">
                                <p className="here_help_right_first_line font-small">Where can I report agents?</p>
                            </div>
                            <div className="col-4 text-end">
                                <div className="plus_img">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
                                        <path d="M9 2V16.1421M16.0711 9.07107H1.92893" stroke="#FF4600" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="row border-bottom pt-3 pb-1">
                            <div className="col-8">
                                <p className="here_help_right_first_line font-small">How can I become a Go121 agent?</p>
                            </div>
                            <div className="col-4 text-end">
                                <div className="plus_img">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
                                        <path d="M9 2V16.1421M16.0711 9.07107H1.92893" stroke="#FF4600" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
