import React from 'react'
import HedersUpArrowImg from '../../assets/images/about us/about_us_up_arrow_img.png';
import HedersGirlImg from '../../assets/images/about us/about_us_header_girl_pic.png';
import HedersGirlImgMobile from '../../assets/images/about us/about_us_header_girl_picM.png';


export const AboutUsHeader = () => {
    return (
        <div className='header-section'>
            <div className='about_us_header_section grid'>
                <div className='about_us_header_section_left_side_content'>
                    <p className='about_us_header_section_left_side_content_first_line font-xsmall'>About us</p>
                    <h1 className='about_us_header_section_left_side_content_third_line font-large'>We give our agents the tools to <span className='about_us_header_section_left_side_content_third_line_inside_line'> create their own income</span></h1>
                    <p className='about_us_header_section_left_side_content_second_line font-medium'>By selling much-wanted products and services directly to their communities.</p>
                </div>

                <div className='about_us_header_section_girl_img'>
                    <img src={HedersGirlImg} alt="loading" />
                </div>
                <div className='about_us_header_section_girl_img_mobile'>
                    <img src={HedersGirlImgMobile} alt="loading" />
                </div>
            </div>
        </div>
    )
}
