import axios from 'axios';
import appConfig from '../../config/constant';

const API = axios.create({
    baseURL: appConfig.BASE_URL
});

API.interceptors.response.use(
    (response) => {
        if (response.data.data) {
            return response.data.data;
        }
        else {
            return response.data ? response.data : {};
        }
    }, (error) => {
        if (error?.response?.status === 401) {
            window.location.href = '/';
        }
        return error.response && error.response.data ? error.response.data : {};

    }
);


export default API;