import React from 'react'
import { HelpCenterHeader } from '../../components/help-center/help-center-header';
import { HelpCenterHeaderCards } from '../../components/help-center/help-center-header-cards';
import { PopularSalesAgent } from '../../components/help-center/popular-sales-agent';
import { PopularSalesAgentBelowCards } from '../../components/help-center/popular-sales-agent-below-cards';
import { SalesAgent } from '../../components/become-an-agent/sales-agent';
import { HelpCenterThankYouT } from '../../components/help-center-thank-you/help-center-thank-you-t';

export const HelpCenterThankYou = () => {
    return (
        <div>
            <HelpCenterHeader />
            <HelpCenterHeaderCards />
            <PopularSalesAgent />
            <PopularSalesAgentBelowCards />
            <HelpCenterThankYouT/>
            <SalesAgent />
        </div>
    )
}
