import React from 'react'
import GroupGrilsWithIconImg from '../../assets/images/become a partner/group_girls_with_icon_pic.png';
import GroupGrilMobileViewImg from '../../assets/images/become a partner/girls_group_mobile_view_pic.png';


export const CreateJobs = () => {
    return (
        <div className='header-section-white'>
            <div className='become_a_partner_create_jobs grid'>
                <div className='become_a_partner_create_jobs_left_side_img'>
                    <img src={GroupGrilsWithIconImg} alt="loading" />
                </div>
                <div className='become_a_partner_create_jobs_left_side_mobile_view_img'>
                    <img src={GroupGrilMobileViewImg} alt="loading" />
                </div>

                <div className='become_a_partner_create_jobs_right_side_content'>
                    <p className='become_a_partner_create_jobs_right_side_content_first_line font-xsmall'>why become a corporate partner</p>
                    <h1 className='become_a_partner_create_jobs_right_side_content_second_line font-large'>Reach your target market and create jobs</h1>
                    <div className='my-xs-0 my-4'>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none" preserveAspectRatio="xMinYMin">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="become_a_partner_create_jobs_right_side_content_third_line font-small">Your products and services become tools that empower those hungry for work.</li>
                        </ul>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none" preserveAspectRatio="xMinYMin">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="become_a_partner_create_jobs_right_side_content_third_line font-small">Benefit from a network of sales agents who function as trusted brand ambassadors in their communities.</li>
                        </ul>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none" preserveAspectRatio="xMinYMin">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="become_a_partner_create_jobs_right_side_content_third_line font-small">Trained sales agents provide a direct link between suppliers and customers.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
