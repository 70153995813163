import React from 'react'
import { SalesAgent } from '../../components/become-an-agent/sales-agent';
import { HelpCenterHeader } from '../../components/help-center/help-center-header';
import { HelpCenterHeaderCards } from '../../components/help-center/help-center-header-cards';
import { PopularSalesAgent } from '../../components/help-center/popular-sales-agent';
import { PopularSalesAgentBelowCards } from '../../components/help-center/popular-sales-agent-below-cards';
import { StillHaveQuestion } from '../../components/help-center/still-have-question';

export const HelpCenter = () => {
    return (
        <div>
            <HelpCenterHeader/>
            <HelpCenterHeaderCards/>
            <PopularSalesAgent/>
            <PopularSalesAgentBelowCards/>
            <StillHaveQuestion/>
            <SalesAgent/>
        </div>
    )
}
