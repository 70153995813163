import React from 'react'
import leftArrow from '../../assets/images/about us/left_arrow.svg';
import rightArrow from '../../assets/images/about us/right_arrow.png';

export const Testimonials = () => {
    return (
        <div className='sales-section'>
            <div className='about_us_testimonials grid'>
                <div className='about_us_testimonials_carousel'>
                    <div id="carouselExampleIndicators" className="carousel slide about_us_testimonial_carousel_flex" data-bs-ride="carousel">
                        <div className="carousel-indicators about_us_testimonials_indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="about_us_testimonials_buttons">
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true">
                                    <img src={leftArrow} alt="loading" className="leftArrow" />
                                </span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <img src={rightArrow} alt="loading" className="rightArrow" />
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                        <div className="carousel-inner about-us-testemonials-carousel-inner">
                            <div className="carousel-item active">
                                <div className='grid about-us-carousel-grid'>
                                    <div className='about_us_testimonials_carousel_content'>
                                        <p className='about_us_testimonials_carousel_content_first_line font-xsmall'>Testimonials</p>
                                        <h5 className='about_us_testimonials_carousel_content_second_line font-medium'>"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae sed aliquam quisque nec nec sit urna, gravida id. Nulla in velit varius fusce tincidunt"</h5>
                                        <p className='about_us_testimonials_carousel_content_third_line font-small'>Genie Botha - Customer</p>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='grid about-us-carousel-grid'>
                                    <div className='about_us_testimonials_carousel_content'>
                                        <p className='about_us_testimonials_carousel_content_first_line font-xsmall'>Testimonials</p>
                                        <h5 className='about_us_testimonials_carousel_content_second_line font-medium'>"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae sed aliquam quisque nec nec sit urna, gravida id. Nulla in velit varius fusce tincidunt"</h5>
                                        <p className='about_us_testimonials_carousel_content_third_line font-small'>Genie Botha - Customer</p>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='grid about-us-carousel-grid'>
                                    <div className='about_us_testimonials_carousel_content'>
                                        <p className='about_us_testimonials_carousel_content_first_line font-xsmall'>Testimonials</p>
                                        <h5 className='about_us_testimonials_carousel_content_second_line font-medium'>"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae sed aliquam quisque nec nec sit urna, gravida id. Nulla in velit varius fusce tincidunt"</h5>
                                        <p className='about_us_testimonials_carousel_content_third_line font-small'>Genie Botha - Customer</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='about_us_testimonial_carousel_buttons'>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
