import React, { useState } from "react";
import OkImg from "../../assets/images/ok_pic.png";
import OkImgMobile from "../../assets/images/ok_pic_mobile.png";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Notification from "../../components/notification";

const FormSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    surname: Yup.string().required("Surname is required"),
    company: Yup.string().required("Company is required"),
    email: Yup.string().email("Please enter a valid email").required("Email is required"),
    contactNumber1: Yup.string().required("Contact number is required"),
    message: Yup.string().required("Message is required"),
})

export const SignupForm: React.FC = () => {

    const [isCheckReCaptcha, setIsCheckReCaptcha] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const formik = useFormik({
        validationSchema: FormSchema,
        initialValues: {
            firstName: "",
            surname: "",
            company: "",
            email: "",
            contactNumber1: "",
            message: "",
        },
        onSubmit: (value) => {
            setIsSubmitted(true);
            console.log(value);
        }
    })

    const onChange = (value: any) => {
        setIsCheckReCaptcha(!value);
    }

    return (
        <div className="header-section">
            <Notification
                type="warn"
                text="Sorry, we're experiencing problems with our system. Please try again in a few minutes."
            />
            <div className="partner_sign_up_form_new grid">
                <div className="partner_sign_up_form_first_row_new">
                    <p className='partner_sign_up_breadcrumb'>
                        <span className='partner_sign_up_breadcrumb_first'>Corporate partner</span>
                        <span className='partner_sign_up_breadcrumb_second'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
                                <path d="M6 3L11 8L6 13" stroke="#FF4600" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Sign up your business
                        </span>
                    </p>
                </div>
                <div className="partner_sign_up_form_second_row_left_side_content_new">
                    <div className="partner_sign_up_left_container">
                        <p className="partner_sign_up_left_first_text">
                            become a corporate partner
                        </p>
                        <p className="partner_sign_up_left_second_text">
                            Let’s talk
                        </p>
                        <p className="partner_sign_up_left_third_text">
                            If Go121 sounds like a good fit for your brand, fill in the form or
                            call us.
                        </p>
                        <p className="partner_sign_up_left_fourth_text">+27 083 987 0987</p>
                        <div className="partner_sign_up_left_ok_img_container">
                            <img src={OkImg} className="partner_sign_up_left_ok_img partner_sign_up_left_ok_img_desktop" alt="Okay" />
                            <img src={OkImgMobile} className="partner_sign_up_left_ok_img partner_sign_up_left_ok_img_mobile" alt="Okay" />
                        </div>
                    </div>
                </div>

                <div className="partner_sign_up_form_second_row_right_side_content_new">
                    <form action="" onSubmit={formik.handleSubmit}>
                        <div className="partner_sign_up_form_container">
                            <div className="partner_sign_up_form_header">
                                <div className="partner_sign_up_form_title">
                                    Sign up your business
                                </div>
                            </div>
                            <div className="partner_sign_up_form_body">
                                <div className="partner_sign_up_form_group">
                                    <label htmlFor="" className={`partner_sign_up_form_label ${formik.touched.firstName && formik.errors.firstName ? 'error' : ''}`}>First Name</label>
                                    <input type="text" className={`partner_sign_up_form_input`} {...formik.getFieldProps("firstName")} />
                                    {formik.touched.firstName && formik.errors.firstName &&
                                        <div className="personal_details_form_error">
                                            {formik.errors.firstName}
                                        </div>
                                    }
                                </div>
                                <div className="partner_sign_up_form_group">
                                    <label htmlFor="" className={`partner_sign_up_form_label ${formik.touched.surname && formik.errors.surname ? 'error' : ''}`}>Surname</label>
                                    <input type="text" className={`partner_sign_up_form_input`} {...formik.getFieldProps("surname")} />
                                    {formik.touched.surname && formik.errors.surname &&
                                        <div className="personal_details_form_error">
                                            {formik.errors.surname}
                                        </div>
                                    }
                                </div>
                                <div className="partner_sign_up_form_group">
                                    <label htmlFor="" className={`partner_sign_up_form_label ${formik.touched.company && formik.errors.company ? 'error' : ''}`}>Business name</label>
                                    <input type="text" className={`partner_sign_up_form_input`} {...formik.getFieldProps("company")} />
                                    {formik.touched.company && formik.errors.company &&
                                        <div className="personal_details_form_error">
                                            {formik.errors.company}
                                        </div>
                                    }
                                </div>
                                <div className="partner_sign_up_form_group">
                                    <label htmlFor="" className={`partner_sign_up_form_label ${formik.touched.email && formik.errors.email ? 'error' : ''}`}>Email address</label>
                                    <input type="text" className={`partner_sign_up_form_input`} {...formik.getFieldProps("email")} />
                                    {formik.touched.email && formik.errors.email &&
                                        <div className="personal_details_form_error">
                                            {formik.errors.email}
                                        </div>
                                    }
                                </div>
                                <div className="partner_sign_up_form_group">
                                    <label htmlFor="" className={`partner_sign_up_form_label ${formik.touched.contactNumber1 && formik.errors.contactNumber1 ? 'error' : ''}`}>Contact Number</label>
                                    <input type="text" className={`partner_sign_up_form_input`} {...formik.getFieldProps("contactNumber1")} />
                                    {formik.touched.contactNumber1 && formik.errors.contactNumber1 &&
                                        <div className="personal_details_form_error">
                                            {formik.errors.contactNumber1}
                                        </div>
                                    }
                                </div>
                                <div className="partner_sign_up_form_group">
                                    <label htmlFor="" className={`partner_sign_up_form_label ${formik.touched.message && formik.errors.message ? 'error' : ''}`}>Message</label>
                                    <input type="text" className={`partner_sign_up_form_input`} {...formik.getFieldProps("message")} placeholder="Type your message here" />
                                    {formik.touched.message && formik.errors.message &&
                                        <div className="personal_details_form_error">
                                            {formik.errors.message}
                                        </div>
                                    }
                                </div>
                                <div className="partner_sign_up_form_group">
                                    {/* for test sitekey="6LfVVGUeAAAAAMh00GNJL6MYdqLYIi5iFvb0D3uK" */}
                                    <ReCAPTCHA
                                        sitekey="6Lexn2UeAAAAAFY6T3jmZLrRbcwjeoeVK12M-KQB"
                                        onChange={onChange}
                                    />
                                    {isSubmitted && isCheckReCaptcha && (
                                        <div className="personal_details_form_error">
                                            Please complete recaptcha
                                        </div>
                                    )}
                                </div>
                                <div className="partner_sign_up_form_group">
                                    <button
                                        type='submit'
                                        className="partner_sign_up_form_submit_btn"
                                        disabled={
                                            formik.values.firstName === "" ||
                                                formik.values.surname === "" ||
                                                formik.errors.company ||
                                                formik.errors.contactNumber1 ||
                                                formik.errors.email ||
                                                formik.errors.message ? true : false
                                        }>
                                        <span>Next</span>
                                        <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.88867 12H19.1109M19.1109 12L13.4969 6M19.1109 12L13.4969 18" stroke="#FFBF00" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
