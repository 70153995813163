import React from 'react'
import cardImg1 from '../../assets/images/help_center/Product_1.png';
import cardImg2 from '../../assets/images/help_center/Product_2.png';
import cardImg3 from '../../assets/images/help_center/Product_3.png';
import cardImg4 from '../../assets/images/help_center/Product_4.png';
import cardImg5 from '../../assets/images/help_center/Product_5.png';
import cardImg6 from '../../assets/images/help_center/Product_6.png';

export const HelpCenterHeaderCards = () => {
    return (
        <div className='header-section'>
            <div className='help_center_header_cards grid'>
                <div className='help_center_header_cards_first_row_cards'>
                    <div className='help_center_card_div'>
                        <img src={cardImg1} alt="loading" />
                        <h4 className='help_center_card_div_first_line font-small'>Why become an agent?</h4>
                        <p className='help_center_card_div_second_line font-xsmall'>Lorem ipsum dolor sit amet, consectetur adipiscing elit quisque nec nec sit urna, gravida id</p>
                    </div>
                    <div className='help_center_card_div'>
                        <img src={cardImg2} alt="loading" />
                        <h4 className='help_center_card_div_first_line font-small'>The Go121 starter kit</h4>
                        <p className='help_center_card_div_second_line font-xsmall'>Lorem ipsum dolor sit amet, consectetur adipiscing elit quisque nec nec sit urna, gravida id</p>
                    </div>
                    <div className='help_center_card_div'>
                        <img src={cardImg3} alt="loading" />
                        <h4 className='help_center_card_div_first_line font-small'>POS machines</h4>
                        <p className='help_center_card_div_second_line font-xsmall'>Lorem ipsum dolor sit amet, consectetur adipiscing elit quisque nec nec sit urna, gravida id</p>
                    </div>
                </div>

                <div className='help_center_header_cards_second_row_cards'>
                    <div className='help_center_card_div'>
                        <img src={cardImg4} alt="loading" />
                        <h4 className='help_center_card_div_first_line font-small'>Products and Services</h4>
                        <p className='help_center_card_div_second_line font-xsmall'>Lorem ipsum dolor sit amet, consectetur adipiscing elit quisque nec nec sit urna, gravida id</p>
                    </div>
                    <div className='help_center_card_div'>
                        <img src={cardImg5} alt="loading" />
                        <h4 className='help_center_card_div_first_line font-small'>The Go121 app</h4>
                        <p className='help_center_card_div_second_line font-xsmall'>Lorem ipsum dolor sit amet, consectetur adipiscing elit quisque nec nec sit urna, gravida id</p>
                    </div>
                    <div className='help_center_card_div help_center_card_div_mobile'>
                        <img src={cardImg6} alt="loading" />
                        <h4 className='help_center_card_div_first_line font-small'>Technical support</h4>
                        <p className='help_center_card_div_second_line font-xsmall'>Lorem ipsum dolor sit amet, consectetur adipiscing elit quisque nec nec sit urna, gravida id</p>
                    </div>
                </div>
                <div className='help_center_header_cards_first_row'>

                </div>
            </div>
        </div>
    )
}
