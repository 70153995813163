import React from 'react';
import './App.css';
// import '../src/assets/css/style.css'
//import '../src/assets/css/main.css'
import '../src/assets/css/fonts.css'
import '../src/assets/css/welcome_page.css'
import '../src/assets/css/get_started.css'
import '../src/assets/css/partner.css'
import '../src/assets/css/backgrounds.css'
import '../src/assets/css/people_with_products.css'
import '../src/assets/css/home_sales_agent.css'
import '../src/assets/css/become_go_121_sales_agent.css'
import '../src/assets/css/brand_benefit.css'
import '../src/assets/css/ready_to_become_sales_agent.css'
import '../src/assets/css/become_an_agent.css'
import '../src/assets/css/be_your_own_boss.css'
import '../src/assets/css/how_to_become_agent.css'
import '../src/assets/css/make_money.css'
import '../src/assets/css/it_is_convinient.css'
import '../src/assets/css/here_help.css'
import '../src/assets/css/what_is_go_121.css'
import '../src/assets/css/community_first.css'
import '../src/assets/css/meet_our_agents.css'
import '../src/assets/css/products_and_services.css'
import '../src/assets/css/become_a_partner.css'
import '../src/assets/css/create_jobs.css'
import '../src/assets/css/powerful_sales_tool.css'
import '../src/assets/css/three_easy_steps.css'
import '../src/assets/css/our_biggest_brands.css'
import '../src/assets/css/corporate_partner.css'
import '../src/assets/css/about_us_header.css'
import '../src/assets/css/partner_sign_up_form.css'
import '../src/assets/css/partner_sign_up_form_new.css'
import '../src/assets/css/partner_sign_up_error.css'
import '../src/assets/css/partner_sign_up_thank_you.css'
import '../src/assets/css/about_us_our_values.css'
import '../src/assets/css/empower_community.css'
import '../src/assets/css/about_us_cards.css'
import '../src/assets/css/join_our_team.css'
import '../src/assets/css/testemonials.css'
import '../src/assets/css/our_story.css'
import '../src/assets/css/about_us_ready_to_become_partner.css'
import '../src/assets/css/help_center.css'
import '../src/assets/css/help_center_cards.css'
import '../src/assets/css/help_center_here_help.css'
import '../src/assets/css/popular_sales_agent_below_cards.css'
import '../src/assets/css/still_have_questions.css'
import '../src/assets/css/still_have_a_question_error.css'
import '../src/assets/css/still_have_a_question_thank_you.css'
import '../src/assets/css/verify_an_agent_why_become_an_agent.css'
import '../src/assets/css/help_center_search_on_result_error.css'
import '../src/assets/css/was_this_artical_helpful.css'
import '../src/assets/css/thank_you_page.css'
import '../src/assets/css/default_error_page.css'
import '../src/assets/css/privacy_policy.css'
import '../src/assets/css/verify_an_agent_why_become_an_agent.css'
import '../src/assets/css/help_center_benefits.css'
import '../src/assets/css/why_get_pos_machine.css'
import '../src/assets/css/verify_an_agent_safety_first.css'
import '../src/assets/css/verify_an_agent.css'
import '../src/assets/css/stuck_we_here_to_help.css'
import '../src/assets/css/verify_an_agent_header_error.css'
import '../src/assets/css/verify_an_agent_header_success.css'
import '../src/assets/css/sipho_is_trained.css'
import '../src/assets/css/recommendations.css'
import '../src/assets/css/terms_and_conditions.css'
import '../src/assets/css/terms_and_conditions_content.css'
import '../src/assets/css/error_message.css'
import '../src/assets/css/carousel.css'
import '../src/assets/css/navbar.css'
import '../src/assets/css/footer.css'
import '../src/assets/css/grids.css'
import '../src/assets/css/notification.css'
import '../src/assets/css/personal_details.css'
import '../src/assets/css/personal_details_form_otp.css'
import '../src/assets/css/cell_phone_input.css'
import '../src/assets/css/address_details.css'

import { BrowserRouter, Switch } from 'react-router-dom';
import { Routes } from './routes';
import 'aos/dist/aos.css';
import "animate.css/animate.min.css";


//fonts
import "./assets/fonts/AzoSans-Black.ttf";
import "./assets/fonts/AzoSans-BlackItalic.ttf";
import "./assets/fonts/AzoSans-Bold.ttf";
import "./assets/fonts/AzoSans-BoldItalic.ttf";
import "./assets/fonts/AzoSans-Italic.ttf";
import "./assets/fonts/AzoSans-Light.ttf";
import "./assets/fonts/AzoSans-LightItalic.ttf";
import "./assets/fonts/AzoSans-Medium.ttf";
import "./assets/fonts/AzoSans-MediumItalic.ttf";
import "./assets/fonts/AzoSans-Regular.ttf";
import "./assets/fonts/AzoSans-Thin.ttf";
import "./assets/fonts/AzoSans-ThinItalic.ttf";

function App() {
  return (
    <BrowserRouter >
      <Switch>
          <Routes />
      </Switch>
    </BrowserRouter >
  );
}

export default App;
