import React, { useState } from 'react'

interface Props {
    text: string;
    type: "success" | "warn" | "primary";
    className?: string;
}

const Notification: React.FC<Props> = ({ text, type = 'success', className }) => {
    const [visible, setVisible] = useState(true);

    return (
        <div className={`notification_container notification_container_${type} ${className}`} hidden={!visible}>
            <div className={`notification_prefix_icon`}>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM12 18.2566C11.1716 18.2566 10.5 17.585 10.5 16.7566V11.2566C10.5 10.4281 11.1716 9.75657 12 9.75657C12.8284 9.75657 13.5 10.4281 13.5 11.2566V16.7566C13.5 17.585 12.8284 18.2566 12 18.2566ZM12.0095 8.09995C12.8379 8.09995 13.5095 7.42838 13.5095 6.59995C13.5095 5.77152 12.8379 5.09995 12.0095 5.09995H11.9897C11.1613 5.09995 10.4897 5.77152 10.4897 6.59995C10.4897 7.42838 11.1613 8.09995 11.9897 8.09995H12.0095Z" fill="currentColor" />
                </svg>
            </div>
            <div className={'notification_text'}>{text}</div>
            <div className={'notification_suffix_icon'} onClick={() => setVisible(false)}>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M6 6L18 18M18 6L6 18" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </div>
    );
};

export default Notification;
