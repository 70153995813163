import React from 'react'
import BoysGroupWithIconImg from '../../assets/images/become a partner/boys_group_with_icon_pic.png';
import BoysGroupImg from '../../assets/images/become a partner/boys_group_img.png';
import BoysGroupImgMobile from '../../assets/images/become a partner/boys_group_imgM.png';
import OkImg from '../../assets/images/become a partner/ok_pic2.png';

export const PowerfulSalesTool = () => {
    return (
        <div className='header-section-white'>
            <div className='become_a_partner_powerful_sales_tool grid'>
                <div className='become_a_partner_powerful_sales_tool_left_side_content'>
                    <p className='become_a_partner_powerful_sales_tool_left_side_content_first_line font-xsmall'>How your company benefits</p>
                    <h1 className='become_a_partner_powerful_sales_tool_left_side_content_second_line font-large'>Word of mouth is a powerful sales tool</h1>
                    <div className='my-xs-0 my-4'>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none" preserveAspectRatio="xMinYMin">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="become_a_partner_powerful_sales_tool_left_side_content_third_line font-small">Our agents are super-sellers who actively drive your brand within their communities.</li>
                        </ul>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none" preserveAspectRatio="xMinYMin">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="become_a_partner_powerful_sales_tool_left_side_content_third_line font-small">Every agent gets regular training and is constantly in the know with updates to keep them upgrading their knowledge and ability to sell.</li>
                        </ul>
                        <ul className="list-inline d-flex">
                            <li className="dot_img_sizes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 28" fill="none" preserveAspectRatio="xMinYMin">
                                <circle cx="3.5" cy="14.5" r="3.5" fill="#055CF5" />
                            </svg></li>
                            <li className="become_a_partner_powerful_sales_tool_left_side_content_third_line font-small">Data captured by our agents, and the technology that drives their sales and surveys help build a better product offering to you.</li>
                        </ul>
                    </div>
                </div>

                <div className='become_a_partner_powerful_sales_tool_right_side_img'>
                    <img src={BoysGroupWithIconImg} alt="loading" />
                </div>
                <div className='become_a_partner_powerful_sales_tool_right_side_mobile_view_img'>
                    <img src={BoysGroupImgMobile} alt="loading" />
                </div>
                {/* <div className='become_a_partner_powerful_sales_tool_ok_img'>
                    
                </div> */}
            </div>
        </div>
    )
}
