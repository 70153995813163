import React from 'react'
// import RightSideArrowBgImg from '../../assets/images/become an agent/right arrow bg image.png';
import RightSideArrowBgImg from '../../assets/images/become an agent/Arrow_img.svg';
// import GreatingImg from '../../assets/images/become an agent/Convenient Greet.svg';
import GreatingNewImg from '../../assets/images/become an agent/Greet_new_pic.png';
import GreatingNewImgMobile from '../../assets/images/become an agent/GreetM.png';
import mobileViewArrowImg from '../../assets/images/become an agent/mobile_view_arrow.png';

export const ItIsConvenient = () => {
    return (
        <div className='header-section-green'>
            <div className="it_is_convenient grid">
                <div className="it_is_convenient_left_side_content">
                    <h1 className="it_is_convenient_first_line font-xlarge">It’s <span style={{ color: "#FFBF00" }}> convenient</span>, it’s <span style={{ color: "#FFBF00" }}> safe</span>, and it’s all done <span style={{ color: "#FFBF00" }}>1-2-1</span></h1>
                </div>
                <div className="greating_img">
                    <img src={GreatingNewImg} alt="loading" />
                </div>
                <div className="greating_img_mobile">
                    <img src={GreatingNewImgMobile} alt="loading" />
                </div>
                <div className="arrow_img">
                    <img src={RightSideArrowBgImg} alt="loading" />
                </div>
                <div className='mobile_view_arrow_img'>
                     <img src={mobileViewArrowImg} alt="loading" />
                </div>
            </div>
        </div>
    )
}
